import { Grid } from 'antd';
import { closeModalAfterSubmit } from 'features/Project/projectSlice';
import SwitcherLanguage from 'features/SwitcherLanguage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import MenuLogout from './MenuLogout';
import { MenuContainer } from './MenuStyle';
import ProfileMenu from './ProfileMenu';

const { useBreakpoint } = Grid;

const Menu = ({ onClickMenu, user, isProtectedPage }) => {
  const location = useLocation();
  const history = useHistory();
  const { lg } = useBreakpoint();
  const [role, setRole] = useState();
  const userData = useSelector((state) => state.authentication.user);
  const isManagableBySearch = useSelector(
    (state) => state.project.isManagableBySearch,
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData && userData.roles[0]) {
      setRole(userData.roles[0]);
    }
  }, [userData]);

  const menuItems = [
    {
      key: 'home',
      label: 'Home',
      path: '/',
    },
    {
      key: 'profile/schedule',
      label: t('nav_consultation'),
      path: '/profile/schedule',
    },
  ];
  let protectedMenuItems, menuMobile;
  if (role === 'expert') {
    protectedMenuItems = [
      {
        key: 'profile',
        label: <ProfileMenu user={user} />,
        path: '/profile',
      },
    ];
    menuMobile = [
      {
        key: 'greet',
        label: <ProfileMenu user={user} />,
        // path: '/profile',
      },
      {
        key: 'profile/edit-data',
        label: t('menu_profile'),
        path: '/profile/edit-data',
      },
      {
        key: 'change-password',
        label: t('menu_password'),
        path: '/change-password',
      },
      {
        key: 'sign-in',
        label: <MenuLogout role={role} history={history} />,
        path: '/sign-in',
      },
    ];
  } else {
    protectedMenuItems = [
      {
        key: 'switcher',
        label: (
          <div>
            <SwitcherLanguage />
          </div>
        ),
        path: '/switcher',
      },
      {
        key: 'projects',
        label: t('nav_project_list'),
        path: '/project',
      },
      {
        key: 'profile/schedule',
        label: t('nav_consultation'),
        path: '/profile/schedule',
      },
      {
        key: 'profile',
        label: <ProfileMenu user={user} />,
        path: '/profile',
      },
    ];
    menuMobile = [
      {
        key: 'greet',
        label: <ProfileMenu user={user} />,
        // path: '/profile',
      },
      {
        key: 'profile/edit-data',
        label: t('menu_profile'),
        path: '/profile/edit-data',
      },
      {
        key: 'profile/change-password',
        label: t('menu_password'),
        path: '/profile/change-password',
      },
      {
        key: 'projects',
        label: t('nav_project_list'),
        path: '/projects',
      },
      {
        key: 'profile/schedule',
        label: t('nav_consultation'),
        path: '/profile/schedule',
      },
      {
        key: 'sign-in',
        label: <MenuLogout role={role} history={history} />,
        path: '/sign-in',
      },
    ];
  }

  const determineMenuProtected = () => {
    if (!isManagableBySearch) {
      return protectedMenuItems;
    } else {
      return [
        protectedMenuItems[0],
        protectedMenuItems[1],
        protectedMenuItems[3],
      ];
    }
  };

  const determineMenuMobile = () => {
    if (!isManagableBySearch) {
      return menuMobile;
    } else {
      return [
        menuMobile[0],
        menuMobile[1],
        menuMobile[2],
        menuMobile[3],
        menuMobile[5],
      ];
    }
  };

  const handleClickMenu = (active) => {
    dispatch(closeModalAfterSubmit());
    onClickMenu();
    let ignorePath = ['profile', 'sign-up', 'sign-in', 'greet', 'switcher'];
    if (ignorePath.includes(active.key)) return;
    history.push(`/${active.key === 'home' ? '' : active.key}`);
  };

  const menuData = () =>
    isProtectedPage
      ? lg
        ? determineMenuProtected()
        : determineMenuMobile()
      : menuItems;

  const selected = () => {
    const findMenu = menuData().find((item) => {
      if (item.key === 'projects') {
        return location.pathname.includes('project');
      } else {
        return location.pathname === item.path;
      }
    });
    return findMenu ? findMenu.key : '';
  };
  return (
    <>
      {!lg ? (
        <div
          style={{
            position: 'absolute',
            top: '33%',
            left: 0,
            marginLeft: '7%',
            zIndex: 99,
            height: '3px',
            width: '85%',
            borderTop: '1px solid rgba(0, 0, 0, 0.06)',
          }}
        ></div>
      ) : null}
      {role && (
        <MenuContainer
          selectedKeys={selected()}
          mode="horizontal"
          items={menuData()}
          onClick={handleClickMenu}
          child={isProtectedPage ? 2 : 1}
          $ismobile={!lg}
        />
      )}
    </>
  );
};

export default Menu;
