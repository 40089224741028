import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projectListLoading: true,
  projectList: [],
  projectListStatus: null,
  projectEligibilityLoading: true,
  projectEligibility: null,
  projectEligibilityStatus: null,
  projectEmpty: null,
  projectReferenceLoading: true,
  projectReference: null,
  projectReferenceStatus: null,
  pageValue: 1,
  searchVal: null,
  showModalAfterSubmit: {
    isLoading: true,
    idProject: null,
    isEligible: null,
    showModal: false,
  },
  contactDataStatus: null,
  PricingDataStatus: null,
  linkMap1: null,
  mapLegend1: null,
  statusLinkMap1: false,
  linkMap2: null,
  mapLegend2: null,
  statusLinkMap2: false,
  isManagableData: null,
  isManagableBySearch: null,
  isOldProject: false,
  statusInternalMap: false,
  internalMap: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    projectListLoading(state, action) {
      state.projectListLoading = action.payload.projectListLoading;
      state.projectList = [];
    },
    projectList(state) {
      state.formLoading = true;
      state.projectReferenceStatus = null;
    },
    projectListEmpty(state, action) {
      state.formLoading = false;
      state.projectEmpty = action.payload.projectEmpty;
      state.projectListStatus = 'Success';
    },
    projectListSuccess(state, action) {
      state.formLoading = false;
      state.projectList = action.payload.projectList;
      state.projectEmpty = action.payload.projectEmpty;
      state.projectListStatus = 'Success';
      state.isManagableData = action.payload.isManagableData;
    },
    projectListFailed(state, action) {
      state.formLoading = false;
      state.projectListStatus = 'Fail';
    },
    projectEligibilityLoading(state, action) {
      state.projectEligibilityLoading =
        action.payload.projectEligibilityLoading;
    },
    projectEligibility(state) {
      state.projectEligibilityLoading = true;
    },
    projectEligibilitySuccess(state, action) {
      state.projectEligibilityLoading = false;
      state.projectEligibility = action.payload.projectEligibility;
      state.projectEligibilityStatus = 'Success';
    },
    projectEligibilityFailed(state, action) {
      state.projectEligibilityLoading = false;
      state.projectEligibilityStatus = 'Fail';
    },
    waitMapReady(state) {},
    waitMapReadyLoading(state) {},
    waitMapReadySuccess(state, action) {
      state.statusLinkMap1 = action.payload.statusLinkMap1;
      state.linkMap1 = action.payload.linkMap1;
      state.mapLegend1 = action.payload.mapLegend1;
      state.statusLinkMap2 = action.payload.statusLinkMap2;
      state.linkMap2 = action.payload.linkMap2;
      state.mapLegend2 = action.payload.mapLegend2;
      state.statusInternalMap = action.payload.statusInternalMap;
      state.internalMap = action.payload.internalMap;
    },
    getManageable(state) {},
    getManageableLoading(state) {},
    getManageableSuccess(state, action) {
      state.isManagableBySearch = action.payload.isManagableData;
    },
    getReferenceBasedonLang(state) {},
    getReferenceBasedonLangLoading(state) {},
    getReferenceBasedonLangSuccess(state, action) {
      state.projectReference = action.payload.result;
      state.isManagableData = action.payload.isManagableData;
      state.isOldProject = action.payload.isOldProject;
    },
    getReferenceBasedonLangFailed(state) {},
    projectReferenceLoading(state, action) {
      state.projectReference = action.payload.projectReferenceLoading;
    },
    projectReference(state) {
      state.projectReferenceLoading = true;
    },
    projectReferenceSuccess(state, action) {
      state.projectReferenceLoading = false;
      state.projectReference = action.payload.result;
      state.linkMap1 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[0]?.map_user
          : null;
      state.statusLinkMap1 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[0]?.map_ready
          : null;
      state.mapLegend1 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[0]?.map_legend
          : null;
      state.linkMap2 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[1]?.map_user
          : null;
      state.statusLinkMap2 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[1]?.map_ready
          : null;
      state.mapLegend2 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[1]?.map_legend
          : null;
      state.statusInternalMap =
        action.payload.isManagableData === true &&
        typeof action.payload.result?.additional_data?.maps === 'object'
          ? action.payload.result?.additional_data?.maps[0]?.map_ready
          : null;
      state.internalMap =
        action.payload.isManagableData === true &&
        typeof action.payload.result?.additional_data?.maps === 'object'
          ? action.payload.result?.additional_data?.maps[0]?.map_user
          : null;
      state.projectReferenceStatus = 'Success';
      state.showModalAfterSubmit.showModal = false;
      state.isManagableData = action.payload.isManagableData;
      state.isOldProject = action.payload.isOldProject;
    },
    projectReferenceFailed(state) {
      state.projectReferenceLoading = false;
      state.projectReferenceStatus = 'Fail';
    },
    setContactDataLoading() {},
    setContactData(state) {
      state.contactDataStatus = null;
    },
    setContactDataSuccess(state) {
      state.contactDataStatus = 'Success';
    },
    setContactDataFailed(state) {
      state.contactDataStatus = 'Fail';
    },
    setPricingDataLoading() {},
    setPricingData(state) {
      state.PricingDataStatus = null;
    },
    setPricingDataSuccess(state) {
      state.PricingDataStatus = 'Success';
    },
    setPricingDataFailed(state) {
      state.PricingDataStatus = 'Fail';
    },
    projectReferenceReset(state) {
      state.projectReferenceLoading = true;
      state.projectReferenceStatus = null;
    },
    projectResultDownload(state) {},
    projectResultDownloadSuccess(state, action) {
      state.projectEligibilityLoading = false;
      state.projectResultDownload = action.payload.projectResultDownload;
    },
    showModalAfterSubmitSuccess(state, action) {
      const prevState = state[action.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[action.payload.name] = {
        ...resultPrev,
        ...action.payload,
      };
    },
    closeModalAfterSubmit(state) {
      state.showModalAfterSubmit.showModal = false;
    },
    setPageValue(state, actions) {
      state.pageValue = actions.payload.page;
    },
    setSearchVal(state, actions) {
      state.searchVal = actions.payload.search;
    },
    resetModalOldProject(state) {
      state.isOldProject = false;
    },
  },
});

export const {
  projectListLoading,
  projectList,
  projectListEmpty,
  projectListSuccess,
  projectListFailed,
  projectEligibilityLoading,
  projectEligibility,
  projectEligibilityStatus,
  projectEligibilitySuccess,
  projectEligibilityFailed,
  projectResultDownload,
  projectResultDownloadSuccess,
  waitMapReady,
  waitMapReadyLoading,
  waitMapReadySuccess,
  getManageable,
  getManageableLoading,
  getManageableSuccess,
  getReferenceBasedonLang,
  getReferenceBasedonLangLoading,
  getReferenceBasedonLangSuccess,
  getReferenceBasedonLangFailed,
  projectReference,
  projectReferenceLoading,
  projectReferenceSuccess,
  projectReferenceFailed,
  projectReferenceReset,
  showModalAfterSubmitSuccess,
  setPageValue,
  setSearchVal,
  closeModalAfterSubmit,
  setContactData,
  setContactDataLoading,
  setContactDataSuccess,
  setContactDataFailed,
  setPricingData,
  setPricingDataLoading,
  setPricingDataSuccess,
  setPricingDataFailed,
  resetModalOldProject,
} = projectSlice.actions;
export default projectSlice.reducer;
