import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, postRequest, putRequest } from 'app/axiosClient';
import { notification } from 'antd';
import {
  getExpertise,
  getExpertiseLoading,
  getExpertiseSuccess,
  getProfile,
  getProfileAvatarList,
  getProfileAvatarListResponse,
  getProfileBankList,
  getProfileBankListResponse,
  getProfileLoading,
  getProfileSuccess,
  getSchedule,
  getScheduleLoading,
  getScheduleSuccess,
  submitProfile,
  submitProfileSuccess,
} from './profileSlice';
import { updateAuthBasedOnProfile } from 'features/Auth/authenticationSlice';
import i18n from 'i18next';

function* getProfileApi(action) {
  yield put(
    getProfileLoading({
      getProfileLoading: true,
    }),
  );
  try {
    let res;
    if (action.payload.type === 'expert') {
      res = yield call(() => getRequest('experts/profile'));
    } else {
      res = yield call(() => getRequest('user/profile'));
    }
    yield put(
      getProfileSuccess({
        role: action.payload.type,
        getProfile: res?.data?.profile,
        isCreated: res?.data?.has_created_profile ? 'Yes' : 'No',
      }),
    );
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_display_profile'),
      description: getErrors(e.data),
    });
  }
}

function* submitProfileApi(action) {
  try {
    let res;
    let url;
    if (action.payload.type === 'expert') {
      if (action.payload.method === 'PUT') {
        url = putRequest('experts/profile', action.payload.data);
      } else {
        url = postRequest('experts/profile', action.payload.data);
      }
    } else {
      url = putRequest('user/profile', action.payload.data);
    }
    res = yield call(() => url);
    yield put(
      submitProfileSuccess({
        submitProfile: res?.data,
      }),
    );
    yield put(
      updateAuthBasedOnProfile({
        name: res?.data.profile.name,
      }),
    );
    notification['success']({
      message: i18n.t('success_update_profile'),
    });
    window.scrollTo(0, 0);
  } catch (e) {
    yield put(
      submitProfileSuccess({
        submitProfile: null,
      }),
    );
    notification['error']({
      message: i18n.t('failed_update_profile'),
      description: e.data.message,
    });
  }
}

function* getExpertiseApi(action) {
  yield put(
    getExpertiseLoading({
      getExpertiseLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() => getRequest('experts/expertises'));
    yield put(
      getExpertiseSuccess({
        getExpertise: res?.data?.expertises,
      }),
    );
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_display_expert'),
    });
  }
}

function* getScheduleApi(action) {
  yield put(
    getScheduleLoading({
      scheduleLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `experts/consultations/schedules${
          action.payload ? `?page=${action.payload}` : '?page=1'
        }`,
      ),
    );
    yield put(
      getScheduleSuccess({
        schedule: res?.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Error',
      description: getErrors(e.data),
    });
  }
}

function* getProfileAvatarListAPI(action) {
  try {
    let res;
    res = yield call(() => getRequest('experts/avatar'));
    yield put(
      getProfileAvatarListResponse({
        avatarList: res?.data.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Error',
      description: getErrors(e.data),
    });
  }
}

function* getProfileBankListAPI(action) {
  try {
    let res;
    res = yield call(() => getRequest('config/banks'));
    yield put(
      getProfileBankListResponse({
        bankList: res?.data.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Error',
      description: getErrors(e.data),
    });
  }
}

const getErrors = (error) => {
  if (!error) return;
  let messages = '';

  if (error.error === 'error_param') {
    for (const property in error.message) {
      messages = `${messages}${property}: ${JSON.stringify(
        error.message[property],
      )},
      )}\n`;
    }
  } else {
    messages = error.message;
  }

  return messages;
};

export default function* rootSaga() {
  yield all([
    takeLatest(getProfile, getProfileApi),
    takeLatest(getExpertise, getExpertiseApi),
    takeLatest(submitProfile, submitProfileApi),
    takeLatest(getSchedule, getScheduleApi),
    takeLatest(getProfileAvatarList, getProfileAvatarListAPI),
    takeLatest(getProfileBankList, getProfileBankListAPI),
  ]);
}
