import { notification } from 'antd';
import { getRequest, getRequestPlain, postRequest } from 'app/axiosClient';
import FileSaver from 'file-saver';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  getManageable,
  getManageableLoading,
  getManageableSuccess,
  getReferenceBasedonLang,
  getReferenceBasedonLangFailed,
  getReferenceBasedonLangLoading,
  getReferenceBasedonLangSuccess,
  projectEligibility,
  projectEligibilityFailed,
  projectEligibilityLoading,
  projectEligibilitySuccess,
  projectList,
  projectListEmpty,
  projectListFailed,
  projectListLoading,
  projectListSuccess,
  projectReference,
  projectReferenceFailed,
  projectReferenceLoading,
  projectReferenceSuccess,
  projectResultDownload,
  setContactData,
  setContactDataFailed,
  setContactDataLoading,
  setContactDataSuccess,
  setPricingData,
  setPricingDataFailed,
  setPricingDataLoading,
  setPricingDataSuccess,
  waitMapReady,
  waitMapReadyLoading,
  waitMapReadySuccess,
} from './projectSlice';

function* projectListAPI(action) {
  yield put(
    projectListLoading({
      formLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `simple-pdd/projects${
          action.payload ? `?${action.payload}&size=10` : ''
        }`,
      ),
    );

    if (res?.status === 204) {
      yield put(
        projectListEmpty({
          projectEmpty: true,
        }),
      );
    } else if (res?.status === 200) {
      yield put(
        projectListSuccess({
          projectList: res?.data,
          projectEmpty: false,
          isManagableData: res?.data && res?.data?.is_manageable_data,
        }),
      );
    }
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_display_project_list'),
    });
    yield put(projectListFailed());
  }
}

function* getManageableAPI(action) {
  yield put(
    getManageableLoading({
      formLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() => getRequest('simple-pdd/projects'));

    if (res?.status === 200) {
      yield put(
        getManageableSuccess({
          isManagableData: res?.data?.is_manageable_data,
        }),
      );
    }
  } catch (e) {}
}

function* projectEligibilityAPI(action) {
  yield put(
    projectEligibilityLoading({
      projectEligibilityLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(`pdd/projects/${action.payload.projectId}`),
    );

    yield put(
      projectEligibilitySuccess({
        projectEligibility: res.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_display_detail_project'),
    });
    yield put(projectEligibilityFailed());
  }
}

function* getProjectReferenceAPI(action) {
  yield put(
    projectReferenceLoading({
      projectReferenceLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.projectId}/references`),
    );

    yield put(
      projectReferenceSuccess({
        result: res.data,
        isManagableData: res?.data?.is_manageable_data,
        isOldProject: res?.data?.is_old_project,
      }),
    );
  } catch (e) {
    // let msg = getErrors(e.data);

    // if (e.data && e.data.error && ERROR_MESSAGE_ID[e.data.error]) {
    //   msg = ERROR_MESSAGE_ID[e.data['error']];
    // }

    notification['error']({
      message: i18n.t('error_get_project_reference'),
      // description: msg,
    });
    yield put(projectReferenceFailed());
  }
}

function* getMapReadyAPI(action) {
  yield put(waitMapReadyLoading());
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.projectId}/references`),
    );

    yield put(
      waitMapReadySuccess({
        statusLinkMap1: res?.data?.summary?.reports?.project_area[0]?.map_ready,
        linkMap1: res?.data?.summary?.reports?.project_area[0]?.map_user,
        mapLegend1: res?.data?.summary?.reports?.project_area[0]?.map_legend,
        statusLinkMap2: res?.data?.summary?.reports?.project_area[1]?.map_ready,
        linkMap2: res?.data?.summary?.reports?.project_area[1]?.map_user,
        mapLegend2: res?.data?.summary?.reports?.project_area[1]?.map_legend,
        statusInternalMap:
          res?.data?.is_manageable_data === true &&
          res?.data?.additional_data?.maps[0]?.map_ready,
        internalMap:
          res?.data?.is_manageable_data === true &&
          res?.data?.additional_data?.maps[0]?.map_user,
      }),
    );
  } catch (e) {}
}

function* getReferenceAPI(action) {
  yield put(getReferenceBasedonLangLoading());
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.projectId}/references`),
    );

    yield put(
      getReferenceBasedonLangSuccess({
        result: res.data,
        isManagableData: res?.data?.is_manageable_data,
        isOldProject: res?.data?.is_old_project,
      }),
    );
  } catch (e) {
    yield put(getReferenceBasedonLangFailed());
  }
}

function* setContactDataAPI(action) {
  yield put(setContactDataLoading());
  try {
    yield call(() =>
      postRequest('simple-pdd/set-preferred-contact', action.payload),
    );

    yield put(setContactDataSuccess());
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_confirm_contact'),
    });
    yield put(setContactDataFailed());
  }
}

function* setPricingDataAPI(action) {
  yield put(setPricingDataLoading());
  try {
    yield call(() => postRequest('subscription/', action.payload));

    yield put(setPricingDataSuccess());
  } catch (e) {
    yield put(setPricingDataFailed());
  }
}

function* projectResultDownloadAPI(action) {
  try {
    let res;
    res = yield call(() => getRequestPlain(action.payload.link));
    FileSaver.saveAs(res.data.data, 'data.jpg');
  } catch (e) {
    yield put(projectEligibilityFailed());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(projectList, projectListAPI),
    takeLatest(projectEligibility, projectEligibilityAPI),
    takeLatest(projectResultDownload, projectResultDownloadAPI),
    takeLatest(projectReference, getProjectReferenceAPI),
    takeLatest(setContactData, setContactDataAPI),
    takeLatest(setPricingData, setPricingDataAPI),
    takeLatest(waitMapReady, getMapReadyAPI),
    takeLatest(getReferenceBasedonLang, getReferenceAPI),
    takeLatest(getManageable, getManageableAPI),
  ]);
}
