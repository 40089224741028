const userData = JSON.parse(window.localStorage.getItem('user_fairatmos'));
let homePath;
if (userData && userData.roles[0] === 'user') {
  homePath = '/projects';
}
if (userData && userData.roles[0] === 'expert') {
  homePath = '/profile/edit';
}

const constants = {
  HOST_URL: process.env.REACT_APP_HOST_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${window.localStorage.getItem('token_fairatmos')}`,
  },
  MAPS_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  PAGINATION: {
    pageSize: 1,
  },
  LANGUAGES: [
    {
      key: 'id',
      value: 'id',
      label: 'Bahasa',
    },
    {
      key: 'en',
      value: 'en',
      label: 'English',
    },
  ],
  HOME_PATH: homePath ? homePath : '/projects',
  LOADER_ID:
    'https://storage.googleapis.com/fairatmos-statics/articles/atmoscheck-loading-id.gif',
  LOADER_EN:
    'https://storage.googleapis.com/fairatmos-statics/articles/atmoscheck-loading-en.gif',
};

export default constants;
