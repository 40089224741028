const GLOBAL_ERROR = [
  'error_get_project_reference',
  'error_get_project_last_page',
  'error_no_access_data',
  'error_param',
  'error_old_password_invalid',
];

const JARVIS_ERROR = [
  'error_jarvis_service_unavailable',
  'error_jarvis_no_area_by_upload',
  'error_jarvis_area_out_of_coverage_by_upload',
  'error_jarvis_area_too_big_by_upload',
  'error_jarvis_area_out_of_coverage_by_polygon',
  'error_jarvis_no_area_by_polygon',
  'error_jarvis_area_too_big_by_polygon',
];

const JARVIS_ERROR_SEA = [
  'error_jarvis_area_out_of_sea_by_polygon',
  'error_jarvis_area_out_of_indonesia_by_polygon',
  'error_jarvis_area_out_of_sea_by_upload',
  'error_jarvis_area_out_of_indonesia_by_upload',
];

const OTP_ERROR = ['error_phone_not_verified'];

export { GLOBAL_ERROR, JARVIS_ERROR, JARVIS_ERROR_SEA, OTP_ERROR };
