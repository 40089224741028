import axios from 'axios';
import i18n from 'i18next';

const axiosClient = axios.create();

function setAxiosHeader(useAuth = true) {
  axiosClient.defaults.timeout = 1000 * 60 * 5; // ms * s * m
  axiosClient.defaults.baseURL = process.env.REACT_APP_HOST_URL;
  axiosClient.defaults.withCredentials = false;
  const headerParams = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': `${i18n.language}`,
  };
  if (useAuth) {
    headerParams['Authorization'] = `Bearer ${window.localStorage.getItem(
      'token_fairatmos',
    )}`;
  }
  // window.localStorage.setItem('lang', JSON.stringify(i18n.language));
  axiosClient.defaults.headers = headerParams;
}

export function getRequest(URL, useAuth = true) {
  setAxiosHeader(useAuth);
  return axiosClient
    .get(`/${URL}`)
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401) {
        window.localStorage.removeItem('token_fairatmos');
        window.localStorage.removeItem('user_fairatmos');
        window.localStorage.removeItem('redirect');
        window.location.href = '/sign-in';
      }
      if (error.response.data.error === 'error_not_verified') {
        postRequest('user/sign-out').then((res) => {
          if (res.data.success) {
            window.location.href = `/check-email?email=${
              JSON.parse(localStorage.getItem('user_fairatmos') || '{}')?.email
            }`;
            window.localStorage.removeItem('token_fairatmos');
            window.localStorage.removeItem('user_fairatmos');
            window.localStorage.removeItem('redirect');
          }
        });
      }
      return Promise.reject(error.response);
    });
}

export function getRequestPlain(URL) {
  setAxiosHeader();
  axiosClient.defaults.baseURL = null;
  return axiosClient
    .get(URL)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export function postRequest(URL, payload, useAuth = true) {
  setAxiosHeader(useAuth);
  return axiosClient
    .post(`/${URL}`, payload)
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401) {
        window.localStorage.removeItem('token_fairatmos');
        window.localStorage.removeItem('user_fairatmos');
        window.localStorage.removeItem('redirect');
        window.location.href = '/sign-in';
      }
      return Promise.reject(error.response);
    });
}

export function postRequestPlain(URL, payload) {
  setAxiosHeader();
  axiosClient.defaults.baseURL = null;
  return axiosClient
    .post(URL, payload)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export function putRequest(URL, payload) {
  setAxiosHeader();
  return axiosClient
    .put(`/${URL}`, payload)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}
export function putRequestPlain(URL, payload) {
  setAxiosHeader();
  axiosClient.defaults.baseURL = null;
  return axiosClient
    .put(URL, payload)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export function patchRequest(URL, payload) {
  setAxiosHeader();
  return axiosClient
    .patch(`/${URL}`, payload)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export function deleteRequest(URL) {
  setAxiosHeader();
  return axiosClient
    .delete(`/${URL}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}
